<template>
  <div class="home">
    <Header ref="header"/>
    <el-container style="min-height: 500px">
      <Aside/>
      <router-view ></router-view>
    </el-container>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/Footer";
import Aside from "@/components/Aside";
import Header from "@/components/Header";

export default {
  name: 'Home',
  components: {
    Aside,
    Footer,
    Header
  },
  methods:{

  },
  data(){
    return {
     
    }
  },
  created() {
    // let menus = this.$store.state.menu.menus;
    // this.$refs.header.handleSelect(menus[0].id);
      // this.$axios.get('/menulist').then(res=>{
      //     let menus = res.data;
      //     this.$store.commit('setMenus', menus);
      //     this.$refs.header.handleSelect(menus[0].id);
      // })
  }
}
</script>

<style scoped>
  .home{
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
  }
</style>