<template>
    <div>
        <p>Copyright &copy; 华龙电影数字制作有限公司 2022</p>
    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>
    div{
        /*height: 100vh;*/
        text-align: center;
        background: #545c64;
        color:#fff;
        /*min-height: 100px;*/
        padding:20px;
        /*margin-top:40px;*/
    }

</style>