<template>
    <el-header class="header">
        <ul role="menubar" class="el-menu menubar">
            <div class="logo" style="padding-left: 15px"><img src="../assets/img/logo.png" alt="">
            </div>
        </ul>
        <el-menu
                 class="el-menu"
                 mode="horizontal"
                 @select="handleSelect"
                 background-color="#545c64"
                 text-color="#fff"
                 active-text-color="#409EFF">
            <el-menu-item :key="index" :index="''+ menu.id" v-for="(menu,index) in rootmenus">
                {{menu.name}}
            </el-menu-item>
            <!--        <el-menu-item index="1" :route="{name: 'Equipmodel.Batch'}">设备型号管理</el-menu-item>-->
            <!--        <el-menu-item index="2" :route="{name: 'EquipList.Equips'}">设备列表</el-menu-item>-->
            <!--        <el-menu-item index="3" :route="{name: 'Internal.Auth'}">内部管理</el-menu-item>-->
        </el-menu>
         <el-dropdown @command="handleCommand" class="personal-center">
          <span class="el-dropdown-link">
            <i class="el-icon-user">{{g_getUser()?g_getUser().nickName:'个人中心'}}</i><i class="el-icon-arrow-down el-icon--right"></i>
          </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="newpass">修改密码</el-dropdown-item>
                        <el-dropdown-item command="logout">退出</el-dropdown-item>
                    </el-dropdown-menu>
         </el-dropdown>
        <el-dialog title="修改密码" :visible.sync="editFormVisible" width="600px" :before-close="handleClose" center>
            <el-form :model="editForm" :rules="rules" ref="editForm" label-width="80px">
                <el-form-item label="旧密码" prop="oldPass">
                    <el-input type="password" v-model="editForm.oldPass" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPass">
                    <el-input type="password" v-model="editForm.newPass" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="checkPass">
                    <el-input type="password" v-model="editForm.checkPass" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item size="large">
                    <el-button type="primary" @click="submitForm('editForm')">确认</el-button>
                    <el-button @click="resetForm('editForm')">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </el-header>
</template>

<script>
    const newpassUrl = '/sys/user/newpass';
    export default {
        name: "Header",
        inject: ['reload'],
        data(){
            var validateCheckPass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.editForm.newPass) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            return {
                initMenu: false,
                editForm: {},
                editFormVisible: false,
                rules:{
                    oldPass: [
                        { required:true, message:'请输入旧密码', trigger: 'blur'},
                        { min: 1, max: 16, message: '长度在 1 到 16 个字符', trigger: 'blur' },
                        { pattern: /^\S.*\S$|(^\S{0,1}\S$)/,  message: '首尾不能为空格', trigger: 'blur' }
                    ],
                    newPass: [
                        { required:true, message:'请输入新密码', trigger: 'blur'},
                        { min: 1, max: 16, message: '长度在 1 到 16 个字符', trigger: 'blur' },
                        { pattern: /^\S.*\S$|(^\S{0,1}\S$)/,  message: '首尾不能为空格', trigger: 'blur' }
                    ],
                    checkPass: [
                        { required:true, message:'请再次输入新密码', trigger: 'blur'},
                        { validator: validateCheckPass, trigger: 'blur' }
                    ]
                }
            }
        },
        methods:{
            logout(){
                this.$store.dispatch('logOut');
                this.$axios.post('/logout');
                let user = this.$store.getters.user;
                if(user && user.orgType){
                    localStorage.setItem('rememberType', user.orgType)
                }
                window.location.reload();
                // this.$router.push({path:'/login'});
            },
            handleSelect: function (index) {
                this.rootmenus.forEach(rootmenu=>{
                    if(rootmenu.id == index){
                        let submenus = rootmenu.children;
                        this.$store.commit('setAsideMenus', submenus);
                    }
                })
            },
            handleCommand(command){
                if(command === 'logout') {
                    this.logout();
                }
                else if(command === 'newpass'){
                    this.editFormVisible = true;
                }
            },
            submitForm(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$axios.put(newpassUrl, this.editForm).then(res=>{
                            this.$message({
                                showClose: true,
                                message: '恭喜你，操作成功',
                                type: 'success',
                                duration: 1500,
                                onClose:() => {
                                }
                            });
                            this.editFormVisible = false
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName){
                this.$refs[formName].resetFields();
                this.editFormVisible = false;
                this.editForm = {}
            },
            handleClose(){
                this.resetForm('editForm');
            }
        },
        computed:{
            rootmenus:{
                get(){
                    return this.$store.state.menu.menus;
                }
            }
        },
        mounted: function () {
            this.$nextTick(function () {
                // 仅在整个视图都被渲染之后才会运行的代码
                if(this.rootmenus.length > 0 && !this.initMenu){
                    if(this.$route.path === '/config/menu'){
                        this.initMenu = true;
                    }else{
                        document.querySelector('header .el-menu-item').click();
                        this.initMenu = true;
                    }
                }
            })
        },
        updated() {
            // console.log("updated");
            if(this.rootmenus.length > 0 && !this.initMenu) {
                // console.log("fresh");
                document.querySelector('header .el-menu-item').click();
                this.initMenu = true;
            }
        }
    }
</script>

<style scoped>
    .header {
        padding: 0;
    }

    .menubar {
        height: 60px;
        float: left;
        width: 300px;
        z-index: 999;
        background: none;
        border: 0;
    }

    .personal-center {
        color: #fff;
        font-size: 16px;
        position: absolute;
        top: 0;
        right: 20px;
        padding: 20px;
    }

    /*.logout:hover{*/
    /*    color:rgb(64, 158, 255);*/
    /*}*/

</style>